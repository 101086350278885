<template>
  <div class="install">
    <!--div id="pp">
      <div class="head_alt">Purchase Key ($10):</div>
      <div class="pp_small">
        This is a lifetime key and good for as long as the product is available. New features will automatically be
        pushed to your browser extension and mobile app.
      </div>
      <div id="smart-button-container">
        <div style="text-align: center;">
          <div id="paypal-button-container"></div>
        </div>
      </div>
    </div-->

    <div class="head">Installation Instructions:</div>

    <ol>
      <li>
        Download app from the
        <a target="_blank" href="https://apps.apple.com/us/app/gf-utils/id1582729671">App Store</a> (Android
        coming soon)
        <div id="AppleAppStore">
          <a target="_blank" href="https://apps.apple.com/us/app/gf-utils/id1582729671"
            ><img :src="mySVG"
          /></a>
        </div>
      </li>
      <li>
        Install
        <a target="_blank" href="https://chrome.google.com/webstore/detail/gf-utils/fjgfhgglhijhmaafflpcbnnpcmlfeipk"
          >GF Utils Chrome Extension</a
        >
      </li>
      <li>Open the GF Utils app and go to settings, you'll see an ID. Copy that ID into the extensions settings so they are synced.</li>
      <li>
        Load Glowforge Designer (<a target="_blank" href="https://app.glowforge.com/">app.glowforge.com</a>) in Google
        Chrome. Once you've loaded a design you should see a green icon in the bottom left corner of the app screen
        along with some details as to the current status of your machine.<br/><br/>If the icon is red, contact us by email:
        support [at] gfutils.com
      </li>
    </ol>

    <div class="head">Known Bugs:</div>
    None
  </div>
</template>

<script>
export default {
  data() {
    return {
      mySVG: require("../assets/AppStore.svg"),
    };
  },
};
</script>

<style scoped>
#AppleAppStore{
  margin:10px 0;
}
#AppleAppStore > svg {
  width: 180px;
  border: 0;
}
</style>
